<template>
  <v-dialog
    scrollable
    persistent
    v-model="show"
    width="500px"
    content-class="rounded-xl"
  >
    <v-card :outlined="$vuetify.theme.dark" rounded="xl">
      <v-card-title class="d-flex justify-space-between">
        <div class="text-h6">Approve Payout</div>
        <v-btn icon @click="modalClosed">
          <v-icon size="26"> mdi-close </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <v-card-text class="py-6">
        <v-form lazy-validation ref="form">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="290px"
            min-width="auto"
            offset-y
            nudge-top="20"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-text-field
                v-model="form.payout_date"
                label="Payout date"
                v-bind="attrs"
                height="42px"
                :rules="[$rules.required]"
                solo
                outlined
                dense
                flat
                append-icon="mdi-calendar"
                v-on="on"
                readonly
                @click:clear="form.payout_date = null"
              ></v-text-field>
            </template>
            <v-date-picker
              color="primary"
              v-model="form.payout_date"
              no-title
              :max="maxDate"
              @input="dateMenu = false"
            ></v-date-picker>
          </v-menu>

          <v-text-field
            type="text"
            v-model="form.utr_number"
            :rules="[$rules.required]"
            flat
            outlined
            dense
            label="UTR"
          ></v-text-field>

          <v-textarea
            :rows="3"
            type="text"
            v-model="form.remark"
            flat
            outlined
            dense
            label="Remark"
          ></v-textarea>
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions class="my-2">
        <v-spacer />
        <v-btn
          color="primary"
          @click="submit"
          class="text-capitalize px-8"
          :loading="btnLoading"
        >
          Approve
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      btnLoading: false,
      dateMenu: false,
      form: {
        payout_date: null,
        utr_number: "",
        remark: "",
      },
    };
  },

  computed: {
    ...mapGetters({
      dialog: "payouts/approveDialog",
    }),

    show: {
      get() {
        return this.dialog.show;
      },
      set() {},
    },

    maxDate() {
      const today = this.$moment();
      return today.format("YYYY-MM-DD");
    },
  },

  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },

  methods: {
    ...mapActions({
      toggleDialog: "payouts/toggleApproveDialog",
      showSnackbar: "snackBar/showSnackbar",
    }),

    openModal() {},

    modalClosed() {
      this.toggleDialog({ show: false });
      this.form = {
        payout_date: null,
        utr_number: "",
        remark: "",
      };
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        const onSuccess = () => {
          this.modalClosed();
          this.$emit("submitted");
        };

        const onFinally = () => {
          this.btnLoading = false;
        };

        const data = {
          payout_id: this.dialog.payout_id,
          payout_date: this.form.payout_date,
          utr_number: this.form.utr_number,
          remark: this.form.remark,
        };

        return this.$request(this.$keys.POST, this.$urls.case.payout.approve, {
          data,
          onSuccess,
          onFinally,
        });
      }
    },
  },
};
</script>