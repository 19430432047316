<template>
  <v-dialog
    scrollable
    persistent
    v-model="show"
    width="500px"
    content-class="rounded-xl"
  >
    <v-card :outlined="$vuetify.theme.dark" rounded="xl">
      <v-card-title class="d-flex justify-space-between">
        <div class="text-h6">Reject Payout</div>
        <v-btn icon @click="modalClosed">
          <v-icon size="26"> mdi-close </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <v-card-text class="py-6">
        <v-form lazy-validation ref="form">
          <v-select
            :menu-props="{
              bottom: true,
              offsetY: true,
            }"
            append-icon="mdi-chevron-down"
            v-model="form.reason"
            :items="reason_list"
            item-text="title"
            item-value="id"
            flat
            outlined
            dense
            label="Select Reason"
            :rules="[$rules.required]"
          ></v-select>
          <v-textarea
            flat
            :rows="3"
            outlined
            v-model="form.other_reason"
            label="Type here..."
            :rules="[$rules.required]"
            v-if="form.reason === 0"
          ></v-textarea>
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions class="my-2">
        <v-spacer />
        <v-btn
          color="primary"
          @click="submit"
          class="text-capitalize px-8"
          :loading="btnLoading"
        >
          Reject
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      btnLoading: false,
      reason_list: [],
      form: {
        reason: null,
        other_reason: "",
      },
    };
  },

  computed: {
    ...mapGetters({
      dialog: "payouts/rejectDialog",
    }),

    show: {
      get() {
        return this.dialog.show;
      },
      set() {},
    },
  },

  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },

  methods: {
    ...mapActions({
      toggleDialog: "payouts/toggleRejectDialog",
      showSnackbar: "snackBar/showSnackbar",
    }),

    openModal() {
      this.getReasonList();
    },

    modalClosed() {
      this.toggleDialog({ show: false });
      this.form = {
        reason: null,
        other_reason: "",
      };
    },

    getReasonList() {
      const onSuccess = (res) => {
        this.reason_list = res.data.data;
        this.reason_list.push({
          id: 0,
          title: "Other Reason",
          type: "other",
        });
      };

      let params = {};
      params["reason_type"] = "reject";

      return this.$request(this.$keys.GET, this.$urls.master.reason.list, {
        params,
        onSuccess,
      });
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        const onSuccess = () => {
          this.modalClosed();
          this.$emit("submitted");
        };

        const onFinally = () => {
          this.btnLoading = false;
        };

        const data = { payout_id: this.dialog.payout_id };

        if (this.form.reason) data["reason_id"] = this.form.reason;
        if (this.form.other_reason)
          data["other_reason"] = this.form.other_reason;

        return this.$request(this.$keys.POST, this.$urls.case.payout.reject, {
          data,
          onSuccess,
          onFinally,
        });
      }
    },
  },
};
</script>